<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        options () {
            return this.$store.state[ this.store ];
        },
    },
    created () {
        if ( this.$store.state[ this.store ].force_reload === true || this.$store.state[ this.store ].items === null ) {
            this.$store.dispatch(this.store + '/get_all');
        }
    },
};

</script>

<template>

    <div>
        <div class="form-group" v-if="options.loading === false">
            <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}<span v-if="allownull === false" style="color: red; font-weight: 900">*</span></label>
                <b-select
                    ref               = "input"
                    class             = "form-control"
                    :size             = "input_class"
                    v-bind:class      = "{ 'is-invalid': error_component }"
                    :aria-label       = "name"
                    :aria-describedby = "name"
                    :id               = "'bnv-form-' + name"
                    v-model           = "model"
                    :disabled         = "disabled || _readonly || options.loading"
                    @change           = "value_changed"
                    >
                    <option value="">- - - - - - -</option>
                    <option v-for="option in options.items" v-bind:key="option.id" :value="option.id">{{ option.label }}</option>
                </b-select>
                <input v-if="_readonly" type="hidden" v-model="model" />
                <div class="invalid-feedback">
                    {{ get_error_message() }}
                </div>
                <div v-if="confirm_message" class="valid-feedback">
                    {{ confirm_message }}
                </div>
        </div>
        <div class="form-group" v-if="options.loading === true">
            <label :for="'bnv-form-loader-' + name">{{ get_label() }}<span v-if="allownull === false" style="color: red; font-weight: 900">*</span></label>
            <input
                type="text"
                class="form-control spinner"
                placeholder="caricamento opzioni..."
                :id="'bnv-form-loader-' + name"
                :disabled="true"
            />
        </div>
    </div>

</template>



