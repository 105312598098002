<script>

const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export default {

    name: 'shopuser',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
        get_admin() {
            if ( this.item.hasOwnProperty('myemployees') && this.item.myemployees.length > 0 ) {
                for ( var i = 0; i < this.item.myemployees.length; i++ ) {
                    if ( this.item.myemployees[ i ].role_id === 5 ) {
                        var first_name = this.item.myemployees[ i ].first_name.toLowerCase();
                        var last_name = this.item.myemployees[ i ].last_name.toLowerCase();
                        var first_name_tokens = first_name.split(' ').map( x => {
                            return x.charAt(0).toUpperCase() + x.slice(1);
                        });
                        var last_name_tokens = last_name.split(' ').map( x => {
                            return x.charAt(0).toUpperCase() + x.slice(1);
                        });
                        var str = first_name_tokens.join(' ');
                        str += ' ' + last_name_tokens.join(' ');
                        return str;
                    }
                }
            }
            return '';
        }
    },
    created () {},

}

</script>

<template>

    <div style="">
        {{ get_admin() }}
    </div>

</template>

