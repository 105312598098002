////////////////////

import Vue    from 'vue';
import Router from 'vue-router';

import login                  from '@/login';
import home                   from '@/home';
import shop_list              from '@/shop/list';
import user_list              from '@/user/list';
import { listpage, formpage } from '@/vue-model-mirror';

const NProgress = require('nprogress');

Vue.use(Router);


    function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/login', component: login },
        { path: '/', component: home },

        { path: '/list/shop', component: shop_list, props: { schema_from_route: 'shop' } },
        { path: '/list/user', component: user_list, props: { schema_from_route: 'user' } },
        { path: '/list/:schema', component: listpage },
        { path: '/form/:schema/:id?', component: formpage },

        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

  router.beforeResolve( async (to, from, next) => {
      NProgress.start()
      await sleep(100);
      next()
  });

//router.afterEach((to, from, next) => {
//});

  router.beforeEach((to, from, next) => {
      // redirect to login page if not logged in and trying to access a restricted page
      const publicPages = [ '/login', '/savepassword' ];
      const authRequired = !publicPages.includes(to.path);
      const loggedIn = localStorage.getItem('user');
  
      if (authRequired && !loggedIn) {
          return next('/login');
      }
  
      next();
  })
