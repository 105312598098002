module.exports = {

    development: {
        //backend_url: 'http://localhost:3000/',
        frontend_url: 'http://localhost:8080/',
        //vol_url: 'http://localhost:8080/',
	backend_url: 'https://api.vetrineonline.com/', // production
        vol_url: 'https://www.vetrineonline.com/', 
        rows_per_page: 25,
        gui: {
            color     : '#229954',
            app_label : 'VETRINE ONLINE',
        },
    },

    production: {
        frontend_url: 'https://vo-panel.digitalautomations.it/',
        backend_url: 'https://api.vetrineonline.com/', // production
        vol_url: 'https://www.vetrineonline.com/',     // production
        //backend_url: 'https://www.volstagingapi.digitalautomations.it/', // staging
        //vol_url: 'https://www.volstaging.digitalautomations.it/',        // staging
        rows_per_page: 25,
        gui: {
            color     : '#229954',
            app_label : 'VETRINE ONLINE',
        },
    },

};



