////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const shop_service = {
    get_count,
    destroy,
	exportcsv,
};

function exportcsv( schema, data = null ) {
    var url = `${config.backend_url}model/${schema}/export`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    window.open(url, '_blank');
}

function destroy( model, id ) {
    var url = `${config.backend_url}model/${ model }/${ id }`;
    var options = {
        method  : 'DELETE',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then( obj => {
            return obj;
        });
}

function get_count( model, data = null) {
    var url = `${config.backend_url}model/${ model }`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg.length;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

