<script>

// TODO: sostituire l'html con component di BootstrapVue

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    created () {
        this.model = typeof this.value === 'undefined' ? false : JSON.parse( JSON.stringify( this.value ) );
    },
    computed: {
        _disabled () {
            if ( this.disabled ) { return true; }
            if ( this._readonly ) { return true; }
            return false;
        }
    },
    methods: {
        _return_model() {
            return this.model ? true : false;
        },
        set_option: function( value ) {
            this.model = value;
        }
    },
};

</script>

<template>

    <div class="form-check" style="margin-bottom: 15px">
        <input
            v-on:keyup.enter="enter_key"
            ref="input"
            class="form-check-input"
            type="checkbox"
            :aria-label="name"
            :aria-describedby="name"
            :id="'bnv-form-' + name"
            :checked="model"
            @change="set_option(!model)"
            :disabled="_disabled"
            :readonly="_readonly"
            />
        <label class="form-check-label" :for="'bnv-form-' + name">
            {{ get_label() }}<span v-if="allownull === false" style="color: red; font-weight: 900">*</span>
        </label>
    </div>

</template>




