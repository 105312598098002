//////////////////////////////

module.exports = {
    linkfunction,
    initialfilter,
    rowclass,
    codice,
}

function codice(row) {
    return row.read === true ? `<span style="padding: 2px;">${ row.codice }</span>` : `<span style="padding: 2px;" class="bg-secondary text-light">${ row.codice }</span>`;
}

function rowclass ( item, type ) {
    if ( item !== null && type === 'row' ) {
        return item.read === false ? 'new' : '';
    }
}


function linkfunction( url, item ) {
    var url = `${config.backend_url}article/${ item.id }/user`;
    var payload = { user_id: 1 };
    var options = {
        method  : 'POST',
        headers : { 'Content-Type': 'application/json', 'Accept': 'application/json', },
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options);
}


function get_user() {
    var userstr = localStorage.getItem('user');
    if ( userstr ) {
        return JSON.parse( userstr );
    }
    return undefined;
}

function initialfilter() {
    var user = get_user();
    if ( user ) { return { 'task.user_id': user.id, 'task.task_label': 'ispezione', }; }
    return {};
}

