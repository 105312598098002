<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', ],
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name">{{ get_label() }}<span v-if="allownull === false" style="color: red; font-weight: 900">*</span></label>
        <textarea
            ref="input"
            :id="'bnv-form-' + name"
            class="form-control"
            v-bind:class="{ 'is-invalid': error_component }"
            v-model="model"
            :placeholder="get_placeholder()"
            rows="6"
            :aria-label="name"
            :aria-describedby="name"
            :disabled="disabled"
            :readonly="_readonly"
        ></textarea>
        <small v-if="helper"  :id="'bnv-form-' + name + '-help'" class="form-text text-muted">{{ helper }}</small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>



