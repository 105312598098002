////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';

var options = {
    model_name    : 'shop',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        //Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        console.log( 'ciaone' );
        if ( state.items === null || state.force_reload === true || ( data.hasOwnProperty('force_reload') && data.force_reload === true ) ) {
            state.service.get_all(getters.model_name + '/v2', data.options)
                .then( response => {
                    if ( data.options.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        }
    },
};

const mutations = {
    ...base.mutations,
};

const getters = {
    ...base.getters,
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};


