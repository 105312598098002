/////////////////////////////

const moment   = require('moment');
const elements = require('./components').default;
const comps    = require('@/form_components');

class types_manager {

    constructor(element_type) {
        this.element_type  = element_type;
        this.schema = {};
    }

    get default_value() {
        if ( this.schema.hasOwnProperty('defaultValue') ) {
            if ( this.schema.defaultValue === null ) {
                return '';
            } else if ( this.schema.defaultValue.constructor.name === 'types_manager' ) {
                return this.schema.defaultValue.get_component(this.schema).format('YYYY-MM-DD');
            } else {
                return this.schema.defaultValue;
            }
        }
    }

    get helper() {
        if ( this.schema.hasOwnProperty('helper') ) {
            return this.schema.helper;
        }
    }

    get search() {
        if ( this.schema.hasOwnProperty('search') ) {
            return this.schema.search;
        }
    }

    get label() {
        if ( this.schema.hasOwnProperty('label') ) {
            return this.schema.label;
        }
    }

    get filter() {
        if ( this.schema.hasOwnProperty('filter') ) {
            return this.schema.filter;
        }
    }

    get min_length() {
        if ( this.schema.hasOwnProperty('min_length') ) {
            return this.schema.min_length;
        }
    }

    get max_length() {
        if ( this.schema.hasOwnProperty('max_length') ) { // frontend conf overrides backend conf
            return this.schema.max_length;
        } else if ( this.hasOwnProperty('length') ) {
            return this.length;
        }
    }

    get precision() {
        if ( this.schema.hasOwnProperty('precision') ) { // frontend conf overrides backend conf
            return this.schema.precision;
        } else if ( this.hasOwnProperty('precision_length') ) {
            return this.precision_length;
        }
    }

    get decimal() {
        if ( this.schema.hasOwnProperty('decimal') ) { // frontend conf overrides backend conf
            return this.schema.decimal;
        } else if ( this.hasOwnProperty('decimal_length') ) {
            return this.decimal_length;
        }
    }

    get placeholder() {
        if ( this.schema.hasOwnProperty('placeholder') ) {
            return this.schema.placeholder;
        }
    }

    get readonly() {
        if ( this.schema.hasOwnProperty('readonly') ) {
            return this.schema.readonly;
        }
    }

    get edit() {
        if ( this.schema.hasOwnProperty('edit') ) {
            return this.schema.edit;
        }
        return false;
    }

    get allow_null() {
        if ( this.schema.hasOwnProperty('allowNull') && this.schema.allowNull === true ) {
            return true;
        }
        return false;
    }

    get store_name() {
        if ( this.schema.hasOwnProperty('references') && this.schema.references.hasOwnProperty('store_name') ) {
            return this.schema.references.store_name;
        } else if ( this.schema.hasOwnProperty('store_name') ) {
            return this.schema.store_name;
        }
    }

    get max_size() {
        if ( this.schema.hasOwnProperty('max_size') ) {
            return this.schema.max_size;
        }
    }

    get mime_types() {
        if ( this.schema.hasOwnProperty('mime_types') ) {
            return this.schema.mime_types;
        }
    }

    get allow_add_option() {
        if ( this.schema.hasOwnProperty('allow_add_option') ) {
            return this.schema.allow_add_option;
        }
        return false;
    }

    get component() {
        return this.get_component();
    }

    // ELEMENTS METHODS

    get_integer_element() {
        if ( this.schema.hasOwnProperty('references') ) {
            this.element_type = 'select';
            return elements.bnvselect;
        }
        return elements.bnvinteger;
    }

    get_date_element() {
        return elements.bnvdate;
    }

    get_boolean_element() {
        if ( this.schema.hasOwnProperty('allowNull') === false || this.schema.allowNull === false ) {
            return elements.bnvboolean;
        } else {
            this.element_type = 'nullboolean';
            var element = elements.bnvnullboolean;
            this.schema.store_name = 'nullboolean';
            return element;
        }
    }

    get_string_element() {
        if ( this.schema.hasOwnProperty('references') ) {
            this.element_type = 'select';
            return elements.bnvselect;
        }
        var element = elements.bnvtext;
        //var max_length = this.length;
        //var error_component = false;
        //var error_message = null;
        //var model = null;
        //element.data = function() {
        //    return { max_length, error_component, error_message, model }
        //}
        return element;
    }

    get_textarea_element() {
        return elements.bnvtextarea;
    }

    get_password_element() {
        return elements.bnvpassword;
    }

    get_hidden_element() {
        return elements.bnvhidden;
    }

    get_now() {
        return moment();
    }

    get_virtual() {
        if ( this.schema.hasOwnProperty('tomany') && this.schema.tomany === true ) {
            this.element_type = 'tomany';
            return elements.bnvtomany;
        } else if ( this.schema.hasOwnProperty('file') && this.schema.file === true ) {
            this.element_type = 'file';
            return elements.bnvfile;
        } else if ( this.schema.hasOwnProperty('hidden') && this.schema.hidden === true ) {
            this.element_type = 'hidden';
            return elements.bnvhidden;
        } else if ( this.schema.hasOwnProperty('select') && this.schema.select === true ) {
            this.element_type = 'select';
            if ( this.schema.hasOwnProperty('references') === false && this.schema.hasOwnProperty('store_name') === true ) {
                this.schema.references = { store_name: this.schema.store_name };
                delete this.schema.store_name;
            }
            return elements.bnvselect;
        } else if ( this.schema.hasOwnProperty('component') ) {
            return comps.hasOwnProperty( this.schema.component ) ? comps[ this.schema.component ] : undefined;
        }
    }

    set_schema( column, frontend_configuration = null ) {
        //console.log( frontend_configuration );
        Object.keys( column ).map( x => {
            if ( [ 'type', 'get', 'set', 'validate', ].indexOf( x ) === -1 ) { this.schema[ x ] = column[ x ]; }
        });
        if ( frontend_configuration !== null ) { // frontend attributes configuration overrides backend configuration
            Object.keys( frontend_configuration ).map( x => {
                if ( [ 'type', 'get', 'set', 'validate', ].indexOf( x ) === -1 ) {
//                  if ( x === 'max_length' ) { // frontend override backend
//                      this.length = frontend_configuration[ x ];
//                  } else {
                        this.schema[ x ] = frontend_configuration[ x ];
//                  }
                }
            });
        }
        //console.log( this.schema );
    }

    // STATIC METHODS


    static STRING(length) { return types_manager._get_obj('text', 'get_string_element', { length }); }

    static DECIMAL(precision_length, decimal_length) { return types_manager._get_obj('integer', 'get_integer_element', { precision_length, decimal_length }); }

    static get INTEGER() { return types_manager._get_obj('integer', 'get_integer_element'); }

    static get DATE() { return types_manager._get_obj('date', 'get_date_element'); }

    static get DATEONLY() { return types_manager._get_obj('date', 'get_date_element'); }

    static get BOOLEAN() { return types_manager._get_obj('boolean', 'get_boolean_element'); }

    static get NOW() { return types_manager._get_obj('now', 'get_now'); }

    static get VIRTUAL() { return types_manager._get_obj('virt', 'get_virtual'); }

    static get TEXT() { return types_manager._get_obj('textarea', 'get_textarea_element'); }

    static get JSON() { return types_manager._get_obj('textarea', 'get_textarea_element'); }

    static get PASSWORD() { return types_manager._get_obj('password', 'get_password_element'); }

    static get HIDDEN() { return types_manager._get_obj('hidden', 'get_hidden_element'); }

    static _get_obj( element_type, component_function, options = null ) {
        var obj = new types_manager(element_type);
        obj.get_component = obj[ component_function ];
        if ( options !== null ) {
            Object.keys( options ).map( x => { obj[ x ] = options[ x ]; } );
        }
        //if ( options !== null ) { console.log( obj ); }
        return obj;
    }

}

export default types_manager;

