//////////////////////////////

module.exports = (type) => {
    var schema = {
        name: {
            type: type.STRING(1024),
            allowNull: false,
            validate: {
                len: {
                    args: [ 3,1024 ],
                    msg: "lunghezza nome: 3 <-> 1024",
                }
            },
        },
    }
    return schema;
}
