////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

import { bnvform_service, userService } from '@/services';

var options = {
    model_name    : 'user',
    send_alert    : false,
    default_order : 'last_name ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    title_form    : 'Utente',
    obj           : {},
    items         : null,
    loading       : false,
    error         : false,
    executed      : false,
    force_reload  : false,
    filters       : {},
    order         : null,
    page          : 1,  // default
    rows_per_page : 25, // default
    tot           : 0,
    tot_users     : 0,
    tot_admins    : 0,
    tot_apps      : 0,
    tot_workers   : 0,
    erased        : false,
	filters       : {},
	like_columns     : [ 'last_name', ],
}

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    export( { commit, state, getters } ) {
		var data = { options: {} };
        data.options.order = state.order === null ? 'last_name ASC' : state.order;
        Object.keys( state.filters ).map( key => {
            data.options[ key ] = state.like_columns.includes( key ) ? `|${ state.filters[ key ] }|` : state.filters[ key ];
        });
        userService.exportcsv('user', data.options);
    },
    destroy( { commit }, id ) {
        console.log('ciao');
        commit('set_erased', false);
        //commit('start_request');
        userService.destroy('user', id)
            .then( obj => {
                commit('success_destroy', obj)
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_by_id({commit}, data) {
        commit('start_request');
        bnvform_service.get_by_id('user', data.id, data.include)
            .then( rows => {
                commit('success_obj', rows)
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_all_count({commit}) {
        var params = {
            // role_id: 3,
        };
        userService.get_count('user', params)
            .then( res => {
                commit('set_tot_users', res);
            })
    },
    get_all( { commit, state }, _data = { options: {} } ) {
        commit('start_request');
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        data.options.order = state.order === null ? 'last_name ASC' : state.order;
        Object.keys( state.filters ).map( key => {
            data.options[ key ] = state.like_columns.includes( key ) ? `|${ state.filters[ key ] }|` : state.filters[ key ];
        });
        bnvform_service.get_all('user', data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create({ commit }, data) {
        commit('start_request');
        bnvform_service.create( 'user', data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update({ commit }, data) {
        commit('start_request');
        bnvform_service.update( 'user', data.id, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    force_reload({commit}) { commit('force_reload'); },
    executed({commit}) { commit('executed'); },
    clear({commit}) { commit('clear'); },
    set_filters( {commit}, filters ) {
        commit('setfilters', filters);
    },
    add_filter( {commit}, filter ) {
        commit('addfilter', filter);
    },
    remove_filter( {commit}, index ) {
        commit('removefilter', index);
    },
    set_order( {commit}, value ) {
        commit('setorder', value);
    },
}

const mutations = {
    ...base.mutations,
    set_erased( state, value ) {
        state.erased = value;
    },
    success_destroy( state, obj ) {
        state.erased   = true;
        var index = null;
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === obj.id ) { index = i; break; }
        }
        if ( index ) { delete state.items[ i ]; }
    },
    set_tot_users( state, value ) {
        state.tot_users   = value.users;
        state.tot_admins  = value.admins;
        state.tot_workers = value.workers;
        state.tot_apps    = value.apps;
    },
    setorder ( state, value ) {
        state.order = value;
    },
    setfilters( state, filters ) {
        state.filters = filters;
    },
    addfilter( state, filter ) {
        var index = Object.keys( filter )[0];
        state.filters[ index ] = filter[ index ];
    },
    removefilter( state, index ) {
        delete state.filters[ index ];
    },
    start_request( state ) {
        state.loading      = true;
        state.error        = false;
        state.executed     = false;
        state.force_reload = false;
    },
    success( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    success_obj( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.error    = false;
        state.executed = false;
    },
    success_list( state, items ) {
        state.items    = items;
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    error( state, error ) {
        state.obj          = error;
        state.loading      = false;
        state.error        = true;
        state.executed     = false;
        state.force_reload = true;
    },
    set_total( state, data ) {
        state.tot = data;
    },
    set_page( state, data ) {
        state.page = data;
    },
    set_rows_per_page( state, data ) {
        state.rows_per_page = data;
    },
    force_reload(state) {
        state.force_reload = true;
    },
    executed(state) {
        state.executed = false;
    },
    clear( state ) {
        state.obj          = {};
        state.items        = null;
        state.loading      = false;
        state.error        = false;
        state.executed     = false;
        state.force_reload = false;
        state.erased       = false;
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

