<script>

import bnvbase from '@/vue-model-mirror/bnvform/components/bnvbase.vue';

export default {
    extends: bnvbase,
    props: [],
    computed: {
        saved_children() { return this.$store.state.category.obj.children || []; },
        subc_executed() { return this.$store.state.subcategory.executed; },
    },
    watch: {
        async children(newv, oldv) {
            if ( newv.length < oldv.length ) {
                var difference = oldv.filter(x => newv.indexOf(x) === -1);
                this.erased_key = this.saved_children.map( x => x.name ).indexOf( difference[ 0 ] );
                if ( this.erased_key > -1 ) {
                    this.enabled = false;
                    var options = { 'classification.u': `[${ this.saved_children[ this.erased_key ].id }]` };
                    this.$store.dispatch('subcategory/get_all', { options, force_reload: true });
                }
            }
        },
        subc_executed(newv, oldv) {
            if ( newv === true && oldv === false ) {
                if ( Array.isArray( this.$store.state.subcategory.items ) && this.$store.state.subcategory.items.length > 0 ) {
                    this.$bvModal.msgBoxOk(`Impossibile elminare la Sottocategoria. Risulta associata a ${ this.$store.state.subcategory.items.length } negozi`)
                        .then(value => {
                          this.boxOne = value
                        })
                        .catch(err => {
                          // An error occurred
                        })
                    this.children.splice(this.erased_key, 0, this.saved_children[ this.erased_key ].name);
                }
                this.enabled = null;
            }
            this.$store.dispatch( 'subcategory/executed' );
        },
    },
    methods: {
        validator(tag) {
            return tag.length > 2 && tag.length < 1025
        },
        _validate: async function() {
            var check = this._validate_allow_null();
            this.state = check;
            return check;
        },
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( this.children.length === 0 ) {
                    this.error_message = 'campo obbligatorio';
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        _return_model() {
            return this.children;
        },
        async _delay(time) {
            return new Promise( (resolve) => { 
                setTimeout(resolve, time);
            });
        },
    },
    created () {
        this.children = this.saved_children.map( x => x.name ) || [];
    },
    data () {
        return {
            children   : [],
            state      : null,
            enabled    : null,
            erased_key : null,
        }
    },
}

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}<span v-if="allownull === false" style="color: red; font-weight: 900">*</span></label>
        <b-form-tags
            input-id="tags-basic"
            v-model="children"
            :tag-validator="validator"
            placeholder="sottocategoria (almeno 3 caratteri)"
            :state="state"
            :disabled="enabled !== null ? !enabled : disabled"
        ></b-form-tags>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>

