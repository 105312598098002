//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: { // insegna
            type: type.STRING(1024),
            allowNull: false,
            validate: {
                len: {
                    args: [ 3,1024 ],
                    msg: "l'insegna deve essere di almeno 3 caratteri",
                }
            },
        },
        partita_iva: {
            type: type.STRING(11),
            allowNull: false,
            validate: {
                len: {
                    args: [11,11],
                    msg: "la partita iva deve essere di 11 caratteri",
                }
            }
        },
        descrizione: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
        codice_fiscale: {
            type: type.STRING(16),
            allowNull: true,
            defaultValue: null,
            validate: { 
                len: {
                    args: [11,16], 
                    msg: "il codice fiscale deve essere di 16 caratteri (o di 11 nel caso sia uguale alla partita iva)",
                }
            }   
        },
        codice_ateco: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        ragione_sociale: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        approved: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        active: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: true,
        },
        latitude: {
            type: type.DOUBLE,
            allowNull: true,
            defaultValue: null,
        },
        longitude: {
            type: type.DOUBLE,
            allowNull: true,
            defaultValue: null,
        },
        classification: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}
