////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

import { bnvform_service, shop_service } from '@/services';

var options = {
    model_name    : 'shop',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    title_form       : 'Ruolo',
    obj              : {},
    items            : null,
    loading          : false,
    error            : false,
    executed         : false,
    force_reload     : false,
    erased           : false,
    page             : 1,  // default
    rows_per_page    : 25, // default
    tot              : 0,
    order            : null,
    shops_active     : 0,
    shops_not_active : 0,
    filters          : {},
	like_columns     : [ 'label', 'ragione_sociale', ],
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    export( { commit, state, getters } ) {
		var data = { options: {} };
        data.options.order = state.order === null ? 'label ASC' : state.order;
        Object.keys( state.filters ).map( key => {
            data.options[ key ] = state.like_columns.includes( key ) ? `|${ state.filters[ key ] }|` : state.filters[ key ];
        });
        shop_service.exportcsv('shop', data.options);
    },
    destroy( { commit }, id ) {
        console.log('ciao');
        commit('set_erased', false);
        //commit('start_request');
        shop_service.destroy('shop', id)
            .then( obj => {
                commit('success_destroy', obj)
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_by_id({commit}, data) {
        commit('start_request');
        bnvform_service.get_by_id('shop', data.id, data.include)
            .then( rows => {
                commit('success_obj', rows)
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_all({commit, state}, _data = { options: {} }) {
        commit('start_request');
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        data.options.order = state.order === null ? 'label ASC' : state.order;
        Object.keys( state.filters ).map( key => {
            data.options[ key ] = state.like_columns.includes( key ) ? `|${ state.filters[ key ] }|` : state.filters[ key ];
        });
        bnvform_service.get_all_filter('shop', data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_count_active({commit}) {
        var params = {
            active  : true,
            include : false,
        };
        shop_service.get_count('shop', params)
            .then( res => {
                commit('set_shops_active', res);
            })
    },
    get_count_not_active({commit}) {
        var params = {
            active  : false,
            include : false,
        };
        shop_service.get_count('shop', params)
            .then( res => {
                commit('set_shops_not_active', res);
            })
    },
    create({ commit }, data) {
        commit('start_request');
        bnvform_service.create( 'shop', data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update({ commit }, data) {
        commit('start_request');
        bnvform_service.update( 'shop', data.id, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    force_reload({commit}) { commit('force_reload'); },
    executed({commit}) { commit('executed'); },
    clear({commit}) { commit('clear'); },
    set_filters( {commit}, filters ) {
        commit('setfilters', filters);
    },
    set_order( {commit}, value ) {
        commit('setorder', value);
    },
};

const mutations = {
    ...base.mutations,
    setfilters( state, filters ) {
        state.filters = filters;
    },
    set_erased( state, value ) {
        state.erased = value;
    },
    set_shops_active( state, value ) {
        state.shops_active = value;
    },
    set_shops_not_active( state, value ) {
        state.shops_not_active = value;
    },
    setorder ( state, value ) {
        state.order = value;
    },
    start_request( state ) {
        state.loading      = true;
        state.error        = false;
        state.executed     = false;
        state.force_reload = false;
    },
    success_destroy( state, obj ) {
        state.erased   = true;
        var index = null;
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === obj.id ) { index = i; break; }
        }
        if ( index ) { delete state.items[ i ]; }
    },
    success( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    success_obj( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.error    = false;
        state.executed = false;
    },
    success_list( state, items ) {
        state.items    = items;
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    error( state, error ) {
        state.obj          = error;
        state.loading      = false;
        state.error        = true;
        state.executed     = false;
        state.force_reload = true;
    },
    set_total( state, data ) {
        state.tot = data;
    },
    set_page( state, data ) {
        state.page = data;
    },
    set_rows_per_page( state, data ) {
        state.rows_per_page = data;
    },
    force_reload(state) {
        state.force_reload = true;
    },
    executed(state) {
        state.executed = false;
    },
    clear( state ) {
        state.obj          = {};
        state.items        = null;
        state.loading      = false;
        state.error        = false;
        state.executed     = false;
        state.force_reload = false;
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};



