import bnvdate        from './bnvdate';
import bnvtext        from './bnvtext';
import bnvselect      from './bnvselect';
import bnvboolean     from './bnvboolean';
import bnvinteger     from './bnvinteger';
import bnvtomany      from './bnvtomany';
import bnvtextarea    from './bnvtextarea';
import bnvnullboolean from './bnvnullboolean';
import bnvfile        from './bnvfile';
import bnvpassword    from './bnvpassword';
import bnvhidden      from './bnvhidden';

export default {
    bnvdate,        // date_format() se non uso <input type="date"
    bnvtext,        // max_length
    bnvselect,      // 
    bnvboolean,     //
    bnvinteger,     // is_int() se non uso <input type="integer"
    bnvtomany,
    bnvtextarea,
    bnvnullboolean,
    bnvfile,
    bnvpassword,
    bnvhidden,
}
