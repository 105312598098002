//////////////////////////////

import Vue from 'vue';
import { store }  from '@/store';
import { router } from '@/helpers';

import './plugins/bootstrap-vue';
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon.vue'
Vue.component('icon', Icon)

import App from '@/App.vue';
Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});

