////////////////////////////////////////

const shop_delete = require('./shop/delete').default;
const user_delete = require('./user/delete').default;
const category_subcategories = require('./category/subcategories').default;

module.exports = {
    shop_delete,
    user_delete,
    category_subcategories,
}

