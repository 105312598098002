<script>

export default {

    name: 'cmp-shop-type',
    props: [ 'item' ],
    data() {
        return {
            icons: {
            },
        }
    },
    computed: {
    },
    methods: {
        get_types() {
            if ( this.item.hasOwnProperty('mytypes') && this.item.mytypes.length > 0 ) {
                var res = [];
                for ( var i = 0; i < this.item.mytypes.length; i++ ) {
                    res.push( this.item.mytypes[ i ].label );
                }
                return res.join(', ');
            }
            return '';
        },
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        {{ get_types() }}
    </div>

</template>



