////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    get_by_id,
    get_all,
    create,
    update,
};

function get_by_id( schema, id, include = null ) {
    var url = `${config.backend_url}model/${schema}/${id}`;
    if ( include ) { url += '?include=true'; }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(`${config.backend_url}model/${schema}/${id}?include=true`, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function get_all( schema, data = null ) {
    var url = `${config.backend_url}model/${schema}`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function create( schema, data, include = false) {
    var url = `${config.backend_url}model/${schema}`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    Object.keys( data ).map( x => {
        payload[ x ] = data[ x ];
    });
    var options = {
        method  : 'POST',
        headers : authHeader(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function update( schema, id, data, include ) {
    var url = `${config.backend_url}model/${schema}/${id}`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    Object.keys( data ).map( x => {
        payload[ x ] = data[ x ];
    });
    var options = {
        method  : 'PATCH',
        headers : authHeader(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


