//////////////////////////////////////////////////

const filters = require('@/filterconfig');
const cmps    = require('./components');
const l_cmps  = require('./list_components');

module.exports = {

    sector: {
        title_list    : 'Settori',
        order         : 'name ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : true,
        edit          : true,
        details       : false,
        details_class : 'd-none', // sparisce su desktop
        include       : false,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'name', sortable: true, label: 'Nome', screen: 'mobile' },
        ],
    },

    category: {
        title_list    : 'Categorie e Sottocategorie',
        order         : 'name ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : true,
        edit          : true,
        details       : true,
        details_class : 'd-none',
        include       : true,
        paginate      : true,
        initialfilter : { hierarchyLevel: 1 },
        fields: [
            { key: 'name', sortable: true, label: 'Categoria', screen: 'mobile' },
            { key: 'subcategories', label: 'Sottocategorie', component: l_cmps.category_subcategories, screen: 'mobile' },
        ],
    },

    shop: {
        title_list    : 'NEGOZI',
        order         : 'label ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        edit          : true,
        delete        : true,
        details       : true,
        details_class : '', // sparisce su desktop
        include       : true,
        paginate      : true,
        search_schema : 'search_shop',
        initialfilter : {},
        fields: [
            { key: 'label', sortable: true, label: 'Insegna', screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato', sortable: true, screen: 'mobile' },
            { key: 'admin', label: 'Admin', component: cmps.user, screen: 'mobile', },
            { key: 'addresses', label: 'Città', component: cmps.shop_citta, screen: 'tablet', sortable: false },
            { key: 'mytypes', label: 'Tipologie', component: cmps.type, screen: 'tablet', sortable: false },
            { key: 'approved', sortable: true, formatter: 'convert_boolean', label: 'Approvato', screen: 'tablet' },
            { key: 'codice_ateco', sortable: false, label: 'Cod. Ateco', screen: 'desktop' },
            { key: 'active', sortable: true, formatter: 'convert_boolean', label: 'Attivo', screen: 'desktop' },
            { key: 'ragione_sociale', sortable: true, label: 'Ragione Sociale', screen: 'desktop' },
            { key: 'id', label: 'id', screen: 'desktop', sortable: false },
            { key: 'linkshop', label: 'Vetrina', component: cmps.shoplink, screen: 'desktop', },
            { key: 'delete', label: 'Azioni', component: l_cmps.shop_delete, display: false },
        ],
    },

    type: {
        title_list    : 'TIPOLOGIE DI NEGOZIO',
        order         : 'label ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : true,
        edit          : true,
        details       : false,
        details_class : 'lg-none', // sparisce su desktop
        include       : false,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'label', sortable: true, label: 'Tipologia', screen: 'mobile' },
        ],
    },

    app: {
        title_list    : 'APP DI TERZE PARTI',
        order         : 'name ASC',
        add           : true,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'name', sortable: true, label: 'Nome', screen: 'mobile' },
            { key: 'description', sortable: false, label: 'Descrizione', screen: 'mobile' },
            { key: 'user.email', sortable: false, label: 'Utente', screen: 'mobile' },
        ],
    },

    user: {
        title_list    : 'UTENTI',
        order         : 'last_name ASC|first_name ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        paginate      : true,
        edit          : true,
        include       : true,
        details_class : '', // sparisce su desktop
        //submit        : true,
        search_schema : 'search_user',
        fields: [
            // sempre visibili { screen: 'mobile' },
            { key: 'last_name', sortable: true, label: 'cognome', screen: 'mobile' },
            { key: 'first_name', sortable: false, label: 'nome', screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'creato', screen: 'desktop', class: ['', 'text-center', ''] },
            // solo tablet { screen: 'tablet' }
            { key: 'email', sortable: false, label: 'email', screen: 'tablet' },
            { key: 'role.label', sortable: false, label: 'ruolo', screen: 'tablet' },
            { key: 'customer.api.label', sortable: false, label: 'cliente', screen: 'tablet' },
            // solo desktop { screen: 'desktop' }
            { key: 'delete', label: 'Azioni', component: l_cmps.user_delete, display: false },
        ],
    },

}

// REFERENCES

// title_list     : titolo della pagina list                               - string
// skip           : campi del model da non trattare                        - string[]
// order          : ordine iniziale della tabella                          - string (es: 'label ASC')
// include        : il backend restituisce i record related                - boolean
// details        : mostra il bottone per i dettagli                       - boolean
// details_class  : classe del bottone                                     - boolean
// edit           : mostra il bottone 'modifica'                           - boolean
// add            : mostra il bottone 'nuovo'                              - boolean
// paginate       : mostra il paginator e abilita la paginazione           - boolean
// primarykey     : attributo degli item da usare come chiave per le row   - string
// link           : attributo degli item da usare come link                - string
// search_schema  : schema per la form di ricerca                          - string
// rowclass       : funzione per l'assegnazione di una classe ad una riga  - function
// linkfunction   : filtro iniziale                                        - object
// fields:
//     key           : nome attributo item                         - string
//     sortable      : abilita l'ordinamento                       - boolean
//     label         : etichetta da mostrare nel th                - string
//     formatter     : funzione per la normalizzazione del dato    - string
//     htmlformatter : funzione per la normalizzazione del dato    - string
//                     che restituisce una stringa html
//     display       : se uguale a false non mostra la colonna     - boolean
//     stickyColumn  : fissa la colonna nello scorrimento orizz.   - boolean
//     class         : classe della cella/colonna                  - string[] ( [ 'classe usata sempre', 'classe della colonna', 'classe del detail' ] ) oppure
//                                                                   string   ( in questo caso la classe verrà usata sempre )
//     screen        : size dal quale far comparire il campo       - string ( 'mobile' | 'tablet' | 'desktop' )

