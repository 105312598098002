<script>

export default {

    name: 'cmp-shop-shop-citta',
    props: [ 'item' ],
    data() {
        return {
            icons: {
            },
        }
    },
    computed: {
    },
    methods: {
        get_citta() {
            if ( this.item.hasOwnProperty('addresses') && this.item.addresses.length > 0 ) {
                for ( var i = 0; i < this.item.addresses.length; i++ ) {
                    if ( this.item.addresses[ i ].label === 'negozio' ) {
                        var citta = null;
                        if ( this.item.addresses[ i ].citta ) { citta = this.item.addresses[ i ].citta; }
                        if ( citta ) {
                            if ( this.item.addresses[ i ].provincia ) { citta += ` (${ this.item.addresses[ i ].provincia })`; }
                            return citta;
                        }
                    }
                }
            }
            return '';
        },
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        {{ get_citta() }}
    </div>

</template>



