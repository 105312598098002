<script>

import Vue      from 'vue';
import menu_bar from '@/layout/components/menu_bar';


Vue.component( 'menu-bar', menu_bar );

export default {
    name: 'app',
    computed: {
        is_logged () {
            return ( this.$store.state.authentication.status.hasOwnProperty('loggedIn') && this.$store.state.authentication.status.loggedIn === true ) ? true : false;
        }
    },
    components: {}
}

</script>

<template>

    <div >
        <menu-bar v-if="is_logged"></menu-bar>
        <b-container style="margin-top: 70px" fluid>
            <router-view :key="$route.fullPath" />
        </b-container>
    </div>

</template>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
