//////////////////////////////

import Vue from 'vue';
import Vuex from 'vuex';

import base_module from '@/vue-model-mirror/stores/base_v2.module';

import { authentication } from '@/store/authentication.module';
import user               from '@/store/user.module';
import nullboolean        from '@/store/nullboolean.module';
import { alert }          from '@/store/alert.module';
import { role }           from '@/store/role.module';
import { type }           from '@/store/type.module';
import { app }            from '@/store/app.module';
import shop               from '@/store/shop.module';
import search_shop        from '@/store/search_shop.module';
import search_user        from '@/store/search_user.module';
import subcategory        from '@/store/subcategory.module';

// not default included stores
var vuex_conf = {
    modules: {
        alert, authentication, nullboolean, user, role, type, app, shop, search_shop, search_user, subcategory,
    }
};
// optionals settings for default included stores
var conf = {
    sector   : { default_order : 'name ASC', },
    category : { default_order : 'name ASC', },
};
// default included stores (based on schema name)
const schemasobj = require.context( '@/schemas', true, /^.*\.schema.js$/ );
var schemas = schemasobj.keys();
for ( var i = 0; i < schemas.length; i++ ) {
    var schema_name = schemas[ i ].replace(/^\.\//, '').replace(/\.schema\.js$/, '');
    if ( vuex_conf.modules.hasOwnProperty( schema_name ) === false ) {
        var options = {
            model_name    : schema_name,
            send_alert    : false,
        };
        if ( conf.hasOwnProperty( schema_name ) === true && conf[ schema_name ].hasOwnProperty( 'default_order' ) ) {
            options.default_order = conf[ schema_name ].default_order;
        }
        var base = new base_module( options );
        vuex_conf.modules[ schema_name ] = {
            namespaced : true,
            ...base,
        };
    }
}

Vue.use(Vuex);
export const store = new Vuex.Store( vuex_conf );

//////////////////////////////

//import Vue from 'vue';
//import Vuex from 'vuex';
//
//import { authentication } from '@/store/authentication.module';
//import user               from '@/store/user.module';
//import nullboolean        from '@/store/nullboolean.module';
//import { alert }          from '@/store/alert.module';
//import { role }           from '@/store/role.module';
//import { type }           from '@/store/type.module';
//import { app }            from '@/store/app.module';
//import shop               from '@/store/shop.module';
//import search_shop        from '@/store/search_shop.module';
//import search_user        from '@/store/search_user.module';
//
//Vue.use(Vuex);
//
//export const store = new Vuex.Store({
//    modules: {
//        alert,
//        authentication,
//		nullboolean,
//        user,
//        role,
//        type,
//        app,
//        shop,
//        search_shop,
//        search_user,
//    }
//});
