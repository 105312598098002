
//const legend    = require('./legend').default;
//const linkxls   = require('./linkxls').default;
//const studio    = require('./studio').default;
//const recapiti  = require('./recapiti').default;
//
//const files_created  = require('./files/created').default;
//const files_label    = require('./files/label').default;
//const files_size     = require('./files/pretty_print_size').default;
//const files_uploader = require('./files/uploader').default;
//
//const diary_type        = require('./diary/type').default;
//const diary_task        = require('./diary/task').default;
//const diary_description = require('./diary/description').default;

const shop_citta  = require('./shop/shop_citta').default;
const shoplink    = require('./shop/shoplink').default;
const user        = require('./shop/user').default;
const type        = require('./shop/type').default;

module.exports = {
//  legend,
//  linkxls,
//  studio,
//  recapiti,
//  files_created,
//  files_label,
//  files_size,
//  files_uploader,
//  diary_type,
//  diary_task,
//  diary_description,
    shop_citta,
    shoplink,
    user,
    type,
}

