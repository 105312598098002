////////////////////

import { authHeader } from '@/helpers';

const env    = process.env.NODE_ENV || 'local';
const config = require('@/config.js')[ env ];

export const userService = {
    login,
    logout,
    getAll,
    reset_password,
    save_password,
    get_challenge,
    get_count,
    destroy,
	exportcsv,
};

function exportcsv( schema, data = null ) {
    var url = `${config.backend_url}model/${schema}/export`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    window.open(url, '_blank');
}

function destroy( model, id ) {
    var url = `${config.backend_url}model/${ model }/${ id }`;
    var options = {
        method  : 'DELETE',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then( obj => {
            return obj;
        });
}

function get_count( model, data = null ) {
    var url = `${config.backend_url}model/${ model }`;
    if ( data ) {
        var params = [];
        console.log( Object.keys( data ) );
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(rows => {
            var res = { users: 0, admins: 0, apps: 0, workers: 0, };
            rows.map( x => {
                if ( x.panel === true ) { res.admins += 1; }
                else if ( x.role_id === 6 ) { res.apps += 1; }
                else if ( x.role_id === 5 ) { res.workers += 1; }
                else if ( x.role_id === 3 ) { res.users += 1; }
                //else { res.users += 1; }
            });
            return res;
        });
}

function get_challenge() {
    var user_id = JSON.parse( localStorage.getItem('user') ).id;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },
    };
    return fetch(`${config.backend_url}model/user/${ user_id }/challenge`, requestOptions)
        .then(handleResponse)
        .then(data => data)
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },
        body: JSON.stringify( { email, password } )
    };

    return fetch(`${config.backend_url}model/user/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function reset_password(email) {
    const requestOptions = {
        method: 'POST',
        headers : authHeader(),
        body: JSON.stringify( { email } )
    };

    return fetch(`${config.backend_url}model/user/resetpassword`, requestOptions)
        .then(handleResponse)
        .then( res => {
            return res;
        })
}

function save_password(jwt, password, confirm_password) {
    const requestOptions = {
        method: 'POST',
        headers : authHeader(),
        body: JSON.stringify( { password, confirm_password } )
    };

    return fetch(`${config.backend_url}model/user/savepassword`, requestOptions)
        .then(handleResponse)
        .then( res => {
            return res;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.backend_url}model/user`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
