////////////////////

import { bnvform_service, shop_service } from '@/services';

export const type = {
    namespaced: true,
    state: {
        title_form    : 'Tipologia di Negozio',
        obj           : {},
        items         : null,
        loading       : false,
        error         : false,
        executed      : false,
        force_reload  : false,
        page          : 1,  // default
        rows_per_page : 25, // default
        tot           : 0,
        order         : null,
        tot_shops     : 0,
    },
    actions: {
        get_by_id({commit}, data) {
            commit('start_request');
            bnvform_service.get_by_id('type', data.id, data.include)
                .then( rows => {
                    commit('success_obj', rows)
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        get_all({commit, state}, data = { options: {} }) {
            commit('start_request');
            data.options.order = state.order === null ? 'label ASC' : state.order;
            bnvform_service.get_all('type', data.options)
                .then( response => {
                    if ( data.options.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        get_all_count({commit}) {
            shop_service.get_count('type')
                .then( res => {
                    commit('set_tot_shops', res);
                })
        },
        create({ commit }, data) {
            commit('start_request');
            bnvform_service.create( 'type', data.payload, data.include )
                .then( obj => {
                    commit('success', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        update({ commit }, data) {
            commit('start_request');
            bnvform_service.update( 'type', data.id, data.payload, data.include )
                .then( obj => {
                    commit('success', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        force_reload({commit}) { commit('force_reload'); },
        executed({commit}) { commit('executed'); },
        clear({commit}) { commit('clear'); },
        set_order( {commit}, value ) {
            commit('setorder', value);
        },
    },
    mutations: {
        set_tot_shops( state, value ) {
            state.tot_shops = value;
        },
        setorder ( state, value ) {
            state.order = value;
        },
        start_request( state ) {
            state.loading      = true;
            state.error        = false;
            state.executed     = false;
            state.force_reload = false;
        },
        success( state, obj ) {
            state.obj      = obj;
            state.loading  = false;
            state.error    = false;
            state.executed = true;
        },
        success_obj( state, obj ) {
            state.obj      = obj;
            state.loading  = false;
            state.error    = false;
            state.executed = false;
        },
        success_list( state, items ) {
            state.items    = items;
            state.loading  = false;
            state.error    = false;
            state.executed = true;
        },
        error( state, error ) {
            state.obj          = error;
            state.loading      = false;
            state.error        = true;
            state.executed     = false;
            state.force_reload = true;
        },
        set_total( state, data ) {
            state.tot = data;
        },
        set_page( state, data ) {
            state.page = data;
        },
        set_rows_per_page( state, data ) {
            state.rows_per_page = data;
        },
        force_reload(state) {
            state.force_reload = true;
        },
        executed(state) {
            state.executed = false;
        },
        clear( state ) {
            state.obj          = {};
            state.items        = null;
            state.loading      = false;
            state.error        = false;
            state.executed     = false;
            state.force_reload = false;
        },
    }
}


