//////////////////////////////

moment  = require('moment');
momentz = require('moment-timezone');

module.exports = (type) => {
    var schema = {
        name: {
            type: type.STRING(64),
            allowNull: false,
        },
        value: {
            type: type.JSON,
            allowNull: true,
            defaultValue: null,
            get() {
                var value = this.getDataValue('value');
                var res = null;
                if ( value !== null ) {
                    switch ( this.getDataValue('type') ) {
                        case 'integer':
                            res = parseInt( value.value ); break;
                        case 'json':
                            res = value; break;
                        case 'file':
                            res = value; break;
                        case 'boolean':
                            res = value.value === 'true' ? true : false; break;
                        case 'string':
                            res = String(value.value); break;
                        case 'dateonly':
                            res = moment( value.value ); break;
                        case 'datetime':
                            res = moment( value.value ); break;
                        case 'datetime_tz':
                            res = momentz( value.value ); break;
                    }
                }
                return res;
            }
        },
        type: {
            type: type.STRING(64),
            allowNull: false,
        },
        message_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'message_shops',
                key         : 'id',
                constraints : true,
                store_name  : 'message_shop',
            }
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}


