<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    name: 'user-delete',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        has_shops() {
            return ( this.item.hasOwnProperty('myworkshops') && Array.isArray( this.item.myworkshops ) && this.item.myworkshops.length > 0 ) ? true : false;
        },
        shops() {
            if ( this.has_shops ) {
                return this.item.myworkshops.map( x => x.label );
            }
            return [];
        },
    },
    methods: {
        delete_user() {
            var options = {
                buttonSize: 'sm',
                title: 'CONFERMA OPERAZIONE',
                titleClass: 'testina'
            };
            const h = this.$createElement;
            var body = h( 'div', [
                h('p', { domProps: { innerHTML: `Eliminare l'utente <code>${ this.item.first_name } ${ this.item.last_name }</code>?` } }),
            ]);
            if ( this.has_shops ) {
                var shop_string = 'Verranno eliminati anche i seguenti negozi:<ul>' + this.shops.map( x => `<li>- <code>${ x }</code></li>` ) + '</ul>';
                body.children.push( h('p', { domProps: { innerHTML: shop_string } }) );
            } else {
                body.children.push( h('p', 'L\'utente non è associato a negozi.') );
            }
            //console.log( body );
            this.$bvModal.msgBoxConfirm(body, options)
                .then(value => {
                    if ( value === true ) {
                        this.$store.dispatch( 'user/destroy', this.item.id );
                    }
                })
                //h('p', 'Verranno eliminati anche eventuali negozi associati.'),
                .catch(err => {
                    console.log( err );
                });
        },
        edit_shop_vol() {
            var user = JSON.parse(localStorage.getItem('user'));
            // var url = config.vol_url + `token-converter?jwt=${ user.token }&shop_id=${ this.item.id }&route=dashboard-company-my-profile`;
            var url = config.vol_url + `token-converter?jwt=${ user.token }&id=${ this.item.id }&route=dashboard-company-my-profile`;
            window.open( url, '_blank' );
        }
    },
    created () {
    },

}

</script>

<template>

    <div class="text-center" style="padding-top: 10px">
        <b-button size="sm" variant="outline-info" @click="edit_shop_vol"><b-icon-pencil-square></b-icon-pencil-square> modifica l'utente</b-button>
        <b-button style="margin-left: 10px;" size="sm" variant="outline-danger" @click="delete_user"><b-icon-trash></b-icon-trash> elimina l'utente</b-button>
    </div>

</template>


<style>
    .testina {
        font-size: 98%;
    }
</style>
