<script>

const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export default {

    name: 'shoplink',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
        get_link() {
            var link =  config.vol_url + 'shop?id=' + this.item.id;
            window.open(link, '_blank');
        }
    },
    created () {},

}

</script>

<template>

    <div style="" title="vai al negozio" @click="get_link()">
        <component
            style      = "margin-right: 3px; cursor: pointer;"
            class      = "rounded-circle"
            is         = "b-icon-box-arrow-right"
            scale      = "0.9"
            font-scale = "1.8"
        ></component>
    </div>

</template>

