<script>

import bnvlist from '@/vue-model-mirror/bnvlist';
import bnvform from '@/vue-model-mirror/bnvform';
const nprogress = require('nprogress');

const options   = require('@/listconfig');
const schemas   = require('@/schemaconfig');

export default {
    name: 'list',
    components: {
        bnvlist,
        bnvform,
    },
    props: [ 'schema_from_route', ],
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    computed: {
        order () {
            return this.$store.state[ this.schema_name ].order;
        },
        qry_options () {
            var options = {};
            if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
            if ( this.options.hasOwnProperty('initialfilter') ) {
                Object.keys( this.options.initialfilter ).map( x => { options[ x ] = this.options.initialfilter[ x ]; } );
            }
            return options;
        },
        title () {         // default: schema_name.toUpperCase()
            return this.options.hasOwnProperty('title_list') ? this.options.title_list : this.schema_name.toUpperCase();
        },
        skip () {          // default: []
            return this.options.hasOwnProperty('skip') ? this.options.skip : [];
        },
        fields () {        // default: undefined
            if ( this.options.hasOwnProperty('fields') ) {
                return this.options.fields.map( column_field => {
                    if ( column_field.hasOwnProperty('class') === false ) {
                        column_field['class'] = [];
                    } else if ( typeof column_field['class'] === 'string' ) {
                        column_field['class'] = [ column_field['class'] ];
                    }
                    if ( column_field.hasOwnProperty( 'screen' ) ) {
                        var classes = [];
                        var screen_classes = this._get_column_class( column_field.screen );
                        for ( var i = 0; i < screen_classes.length; i++ ) {
                            var class_string = typeof column_field['class'][ i ] === 'string' ? column_field['class'][ i ] : '';
                            classes.push( class_string + ' ' + screen_classes[ i ]  );
                        }
                        column_field['class'] = classes;
                        delete column_field.screen;
                    }
                    return column_field;
                });
            }
            return undefined;
        },
        primary_key () {   // default: 'id'
            return this.options.hasOwnProperty('primary_key') ? this.options.primary_key : 'id';
        },
        details () {       // default: true
            return this.options.hasOwnProperty('details') && this.options.details === false ? false : true;
        },
        details_class () { // default: ''
            return this.options.hasOwnProperty('details_class') && this.options.details_class ? this.options.details_class : '';
        },
        details_tooltip () { // default: null
            return this.options.hasOwnProperty('details_tooltip') && this.options.details_tooltip ? this.options.details_tooltip : null;
        },
        details_action () { // default: 'button'
            return this.options.hasOwnProperty('details_action') && this.options.details_action ? this.options.details_action : 'button';
        },
        edit () {          // default: true
            return this.options.hasOwnProperty('edit') && this.options.edit === false ? false : true;
        },
        edit_action () {  // default: 'button'
            return this.options.hasOwnProperty('edit_action') && this.options.edit_action  ? this.options.edit_action : 'button';
        },
        add () {           // default: true
            return this.options.hasOwnProperty('add') && this.options.add === false ? false : true;
        },
        paginate () {      // default: false
            return this.options.hasOwnProperty('paginate') && this.options.paginate === true ? true : false;
        },
        link () {          // default: null
            return this.options.hasOwnProperty('link') ? this.options.link : null;
        },
        search_schema () { // default: null
            if ( this.options.hasOwnProperty('search_schema') ) {
                this.schema = schemas[ this.options.search_schema ];
                this.form_config = {};
                this.record = {};
                this.inline = true; // setta la form inline e modifica i bottoni
                return this.options.search_schema;
            }
            return null;
        },
        rowclass () {
            return this.options.hasOwnProperty('rowclass') ? this.options.rowclass : undefined;
        },
        linkfunction () {
            return this.options.hasOwnProperty('linkfunction') ? this.options.linkfunction : undefined;
        },
        initialfilter () {
            return this.options.hasOwnProperty('initialfilter') ? this.options.initialfilter : {};
        },
        size () {           // default: true
            return this.options.hasOwnProperty('size') ? this.options.size : undefined;
        },
    },
    watch: {
        '$route.params.schema': function (id) { // TODO non sono sicuro serva ancora dopo le modifiche ad App.js
            this.schema_name = this.$route.params.schema;
            this.options = options[ this.schema_name ];
        }
    },
    created () {
        this.schema_name = this.$route.params.schema || this.schema_from_route;
        if ( options.hasOwnProperty( this.schema_name ) === false ) {
            var rows = [
                '\n----------',
                'nel file listconfig.js non c\'è una voce per "' + this.schema_name + '"',
                '----------',
            ];
            console.error( rows.join('\n\n') );
        }
        this.options = options[ this.schema_name ];
        //console.log('listpage/created', Object.keys( this.qry_options ).length );
        if ( this.options.hasOwnProperty('order') && this.order === null ) { this.$store.dispatch(`${ this.schema_name }/set_order`, this.options.order); }
    },
    methods: {
        new_element: function() {
            this.$router.push( `/form/${this.schema_name}` );
        },
        reset_form: function() {
            this.form_reload += 1;
        },
        operation_success: function( data ) {
            //console.log( 'parent' );
            Object.keys( data ).map( x => {
                if ( data[ x ] === null ) {
                    this.$refs.listbnv.remove_qry_filter( x );
                } else {
                    var filter = {};
                    filter[ x ] = data[ x ];
                    this.$refs.listbnv.add_qry_filters( filter );
                }
            });
            this.$refs.listbnv.page = 1;
        },
        reset_search_form: function() {
            //this.form_reload += 1; // metodo di pageform, non sembra funzionare con i modal
            this.$refs.search_form.reseta();
        },
        row_selected: function(row) {
        },
        _get_column_class( screen ) {
            if ( screen === 'mobile' )  { return ['', '', 'd-none']; }
            if ( screen === 'tablet' )  { return ['', 'd-none d-md-table-cell', 'd-md-none']; }
            if ( screen === 'desktop' ) { return ['', 'd-none d-lg-table-cell', 'd-lg-none']; }
            return '';
        },
        right_click( obj ) {
            console.log( 'right button clicked!' );
        },
    },
    data () {
        return {
            form_reload : 0,
            inline      : false,
            item_form   : undefined,
            form        : {
                schema_name   : null,
                schema        : null,
                schema_config : {},
                record        : {},
            },
        }
    },
};

</script>

<template>

    <div style="width: 100%; margin: auto">
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <bnvform
            v-if       = "search_schema"
            :name      = "search_schema"
            :schema    = "schema"
            :options   = "form_config"
            :record    = "record"
            :submit    = "false"
            ref        = "search_form"
            :key       = "form_reload"
            :inline    = "inline"
            @reset     = "reset_form"
            @submitted = "operation_success"
        ></bnvform>
        <b-row style="margin-bottom: 10px">
            <b-col v-if="add" align-self="end" class="text-right">
                <b-button @click="new_element();" size="sm" variant="outline-info"><icon name="plus"/></b-button>
            </b-col>
        </b-row>
        <bnvlist
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailstooltip  = "details_tooltip"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :size            = "size"
            :formpath        = "item_form"
            @row_selected    = "row_selected"
            @right-click     = "right_click"
        ></bnvlist>
    </div>

</template>


