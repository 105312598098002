module.exports = {

    search_shop: {
        settings: { size: 'sm', },
        label: { label: 'Insegna', helper: 'il testo verrà cercato all\'interno dell\'insegna, senza distinzione tra maiuscole e minuscole' },
		'mytypes.id' : { label: 'Tiplogia di negozio' },
		codice_ateco: { label: 'Codice Ateco' },
		ragione_sociale: { label: 'Ragione Sociale', helper: 'il testo verrà cercato all\'interno della Ragione Sociale, senza distinzione tra maiuscole e minuscole' },
		active: { label: 'Il negozio è attivo' },
	},
    search_user: {
        settings: { size: 'sm', },
		last_name: { label: 'Cognome', helper: 'il testo verrà cercato all\'interno dell\'insegna, senza distinzione tra maiuscole e minuscole'  },
		role_id: { label: 'Ruolo' },
		enabled: { label: 'Abilitato al login' },
		panel: { label: 'Pannello di amministrazione' },
	},
    app: {
        name: { label: 'Nome' },
        description: { label: 'Descrizione' },
        user_id: { label: 'Proprietario', readonly: 'edit' },
        id: { skip: true },
        created: { skip: true },
        settings: { layout: 'single' },
    },
    type: {
        label: { label: 'Tipologia' },
        id: { skip: true },
    },
    sector: {
        settings: { size: 'sm', title_edit: 'Modifica di un Settore', title_add: 'Creazione di un Settore' },
        name: { label: 'Nome' },
        id: { skip: true },
    },
    category: {
        settings: { size: 'sm', title_edit: 'Modifica di una Categoria', title_add: 'Creazione di una Categoria' },
        name: { label: 'Nome' },
        subcategories: { label: 'Sottocategorie associate', allow_add_option: true },
        id: { skip: true },
    },
    add_pratica: {
        settings: { size: 'sm' },
        template_id: { label: 'template' },
    },
    fileupload: {},
    meta_file: {
        description: { label: 'descrizione' },
        file: { label: 'immagine' },
        option: { label: 'opzioni precompilate' },
    },
    shop: {
        settings: { size: 'sm', title_edit: 'Modifica di un negozio' },
		label: { label: 'Insegna', readonly: 'edit' },
		partita_iva: { label: 'Partita IVA', readonly: 'edit' },
		descrizione: { label: 'Descrizione', readonly: 'edit' },
		codice_fiscale: { label: 'Codice Fiscale', readonly: 'edit' },
		codice_ateco: { label: 'Codice Ateco', readonly: 'edit' },
		ragione_sociale: { label: 'Ragione Sociale', readonly: 'edit' },
		created: { skip: true, label: 'Creato il', readonly: 'edit' },
		approved: { label: 'Il negozio è stato Approvato' },
		active: { label: 'Il Negozio è attivo' },
		id: { skip: true, label: '', readonly: 'edit' },
		latitude: { label: 'Latitudine', readonly: 'edit' },
		longitude: { label: 'Longitudine', readonly: 'edit' },
    },
    user: {
        settings: { size: 'sm', title_edit: 'Modifica di un utente', },
        id: { skip: true },
        hash: { skip: true },
        created: { skip: true },
        password: { skip: true },
        first_name: { label: 'Nome', readonly: true },
        last_name: { label: 'Cognome', readonly: true },
        email: { label: 'Email', readonly: true },
        role_id: { label: 'Ruolo', readonly: true },
		enabled: { label: 'L\'utente è abilitato al login' },
		panel: { label: 'L\'utente ha accesso al pannello di amministrazione' },
    },
    pratica: {
        settings: { size: 'sm', },
        codice: { readonly: 'edit', label: 'codice pratica' },
        created: { readonly: true, label: 'data creazione' },
        owner: { readonly: 'create', label: 'proprietario'  },
        created_by: { readonly: true, label: 'creato da' },
        closed: { label: 'pratica chiusa', skip: true, },
        operator: { skip: true, },
        operator_notes: { skip: true, },
        state_label: { label: 'stato della pratica', },
        template_id: { skip: true },
    },
    dati_pratica: {
        settings: { size: 'sm', },
        compiler: { readonly: true, },
        pratica_url: { readonly: true, },
    },
    agenzia: {
        settings: { size: 'sm', }
    },
    assicurato: {
        settings: { size: 'sm', }
    },
    controparte: {
        settings: { size: 'sm', }
    },
    referente: {
        settings: { size: 'sm', }
    },
    ufficio: {
        settings: { size: 'sm', }
    },
    search_pratica: {
        settings: { size: 'sm', },
        'assicurato.cognome,assicurato.denominazione': {
            search: 'contains_before_after',
            label: 'assicurato',
            helper: 'cerca la stringa in \'cognome\' e \'denominazione\'',
        },
    },
    customer: {
        label: { label: 'nome' },
        created: { readonly: true, label: 'data creazione' },
    },

//  search_article: {
//      date: {
//          label: "data pubblicazione"
//      },
//      section: {
//          label: "categoria"
//      }
//  },

//  customer: {
//      created: { skip: true },
//      domain: { skip: true },
//      dns_record_id: { skip: true },
//      label: { label: 'nome' },
//      users_number: { label: 'numero di utenti' },
//      active: { label: 'customer attivo' },
//      color: { label: 'colore' },
//      icon: { label: 'icona' },
//      dns_created: { label: 'il record dns è stato creato' },
//      dns_record_name: { label: 'record dns', readonly: 'add' },
//      vhost_created: { label: 'il virtual host è stato creato' },
//  },

}
