//////////////////////////////

module.exports = (type) => {
    var schema = {
        email: {
            type: type.STRING(64),
            isEmail: true,
            allowNull: false,
        },
        first_name: {
            type: type.STRING(64),
            allowNull: false,
        },
        last_name: {
            type: type.STRING(64),
            allowNull: false,
        },
        hash: {
            type: type.STRING(128),
            allowNull: false,
        },
        role_id: {
            type: type.INTEGER,
            allowNull: false,
            defaultValue: 3,
            references: {
                model       : 'roles',
                key         : 'id',
                constraints : true,
                store_name  : 'role',
            }
        },
        enabled: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
        panel: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        password: {
            type: type.VIRTUAL,
            allowNull: false,
            validate: {
                len: {
                    args: [ 8,16 ],
                    msg: "la password deve essere tra gli 8 e i 16 caratteri",
                }
            },
        },
        // campi shop
//        label: {
//            type: type.VIRTUAL,
//            allowNull: true,
//            validate: {
//                len: {
//                    args: [ 5,1024 ],
//                    msg: "l'insegna deve essere di almeno 5 caratteri",
//                }
//            },
//        },
//        type: {
//            type: type.VIRTUAL,
//            allowNull: true,
//        },
//        codice_fiscale: {
//            type: type.VIRTUAL,
//            allowNull: true,
//            validate: {
//                //len: [16,16],
////              is: {
////                  args: [ /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i ],
////                  msg: "il codice fiscale non è valido",
////              }
//            },
//        },
//        partita_iva: {
//            type: type.VIRTUAL,
//            allowNull: true,
//            validate: {
//                //len: [11,11],
////              is_valid( pi ) {
////                  if ( pi ) {
////                  if ( pi.length !== 11 ) { throw new Error("la partita iva deve essere di 11 caratteri"); }
////          		if ( ! /^[0-9]{11}$/.test(pi) ) { throw new Error("la partita iva deve essere composta solo da numeri"); }
////          		var s = 0;
////          		for ( var i = 0; i < 11; i++ ) {
////          			var n = pi.charCodeAt(i) - "0".charCodeAt(0);
////          			if ( (i & 1) === 1 ) {
////          				n *= 2;
////          				if ( n > 9 ) {
////          					n -= 9;
////                          }
////          			}
////          			s += n;
////          		}
////          		if ( s % 10 !== 0 ) { throw new Error("la partita iva non è valida"); }
////                  }
////              }
//          }
//        },
    };

    return schema;
}

