//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(64),
            allowNull: false,
        },
        type: {
            type: type.STRING(64),
            allowNull: true,
        },
        cap: {
            type: type.STRING(64),
            allowNull: true,
        },
        country: {
            type: type.STRING(64),
            allowNull: true,
        },
        regione: {
            type: type.STRING(64),
            allowNull: true,
        },
        citta: {
            type: type.STRING(64),
            allowNull: true,
        },
        provincia: {
            type: type.STRING(64),
            allowNull: true,
        },
        localita: {
            type: type.STRING(64),
            allowNull: true,
        },
        strada: {
            type: type.STRING(64),
            allowNull: true,
        },
        n_civ: {
            type: type.STRING(64),
            allowNull: true,
        },
        geodata: {
            type: type.JSONB,
            allowNull: true,
        },
        shop_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'shops',
                key         : 'id',
                constraints : true,
                store_name  : 'shop',
            }
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}


