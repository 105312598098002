<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    name: 'shop-delete',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        has_users() {
            return ( this.item.hasOwnProperty('myemployees') && Array.isArray( this.item.myemployees ) && this.item.myemployees.length > 0 ) ? true : false;
        },
        users() {
            if ( this.has_users ) {
                return this.item.myemployees.map( x => `${ x.first_name } ${ x.last_name }` );
            }
            return [];
        },
    },
    methods: {
        delete_shop() {
            var options = {
                buttonSize: 'sm',
                title: 'CONFERMA RICHIESTA',
                titleClass: 'testina'
            };
            const h = this.$createElement;
            var body = h( 'div', [
                h('p', { domProps: { innerHTML: `Eliminare il negozio <code>${ this.item.label }</code>?` } }),
            ]);
            if ( this.has_users ) {
                var shop_string = 'NON verranno eliminati i seguenti utenti associati:<ul>' + this.users.map( x => `<li>- <code>${ x }</code></li>` ) + '</ul>';
                body.children.push( h('p', { domProps: { innerHTML: shop_string } }) );
            } else {
                body.children.push( h('p', 'Il negozio non è associato ad utenti.') );
            }
            this.$bvModal.msgBoxConfirm(body, options)
                .then(value => {
                    if ( value === true ) {
                        this.$store.dispatch( 'shop/destroy', this.item.id );
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        edit_shop_vol() {
            var user = JSON.parse(localStorage.getItem('user'));
            console.log(user);
            console.log(user.token);
            const id = this.item.id;
            console.log(`item.id= ${this.item.id}, id=${id}`);
            // var url = config.vol_url + `token-converter?jwt=${ user.token }&shop_id=${ id }&route=dashboard-company-my-shop`; //shop_id NON va perche da vetrine online prende da url id=..
            var url = config.vol_url + `token-converter?jwt=${ user.token }&id=${ id }&route=dashboard-company-my-shop`;
            window.open( url, '_blank' );
        }
    },
    created () {
    },

}

</script>

<template>

    <div class="text-center" style="padding-top: 10px">
        <b-button size="sm" variant="outline-info" @click="edit_shop_vol"><b-icon-pencil-square></b-icon-pencil-square> modifica il negozio</b-button>
        <b-button style="margin-left: 10px;" size="sm" variant="outline-danger" @click="delete_shop"><b-icon-trash></b-icon-trash> elimina il negozio</b-button>
    </div>

</template>


<style>
    .testina {
        font-size: 98%;
    }
</style>
