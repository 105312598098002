<script>

import bnvform from '@/vue-model-mirror/bnvform';
const nprogress = require('nprogress');

const schemas     = require('@/schemaconfig');
const formsconfig = require('../formconfig')

export default {
    components: {
        bnvform,
    },
    props: [ 'schema_from_route', ],
    mounted () {
        this.$nextTick(function () { // stop loader
            nprogress.done(true);
        })
    },
    computed: {
        store () { return this.$store.state[ this.schema_name ]; },
        qry_executed () {
            return this.$store.state[ this.schema_name ].executed;
        },
        record () {
            return typeof this.$route.params.id !== 'undefined' ? this.store.obj : {};
        },
        title () {
            if (
                this.form_config.hasOwnProperty('settings') &&
                ( this.form_config.settings.hasOwnProperty('title') || this.form_config.settings.hasOwnProperty('title_' + this.action) )
               ) {
                return this.form_config.settings.hasOwnProperty('title_' + this.action)
                     ? this.form_config.settings[ 'title_' + this.action ]
                     : this.form_config.settings['title'];
            } else if ( this.$store.state[ this.schema_name ].hasOwnProperty('title_form') ) {
                return this.$store.state[ this.schema_name ];
            }
            return this.schema_name.toUpperCase();
        },
        inline () {
        }
    },
    created () {
        this.schema_name = this.$route.params.schema || this.schema_from_route;
        this.schema = schemas[ this.schema_name ];
        this.options = {}; // non so bene a cosa serva
        this.form_config = formsconfig.hasOwnProperty( this.schema_name ) ? formsconfig[ this.schema_name ] : {};
        // gestione add/edit
        var record_id = this.$route.params.id;
        if ( typeof record_id !== 'undefined' ) {
            this.action = 'edit';
            this.$store.subscribe((mutation, state) => {
                if ( this.started === false && mutation.type === `${this.schema_name}/success_obj` ) {
                    this.started = true;
                }
            });
            this.$store.dispatch(`${this.schema_name}/get_by_id`, { id: record_id, include: true });
        } else {
            this.action = 'add';
            this.started = true;
        }
    },
    methods: {
        reset_form: function() {
            this.form_reload += 1;
        },
        operation_success: function( data ) {
            if ( this.schema.submit !== false ) {
                var title = this.action === 'add' ? `nuovo ${ data.label }` : `modifica ${ data.label }`;
                var body = this.action === 'add' ? 'record creato con successo' : `record salvato con successo`;
                this.make_toast( title.toUpperCase(), body );
            }
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
    },
    data () {
        return {
            form_reload : 0,
            started     : false,
            action      : null,
            form_config : null,
        }
    },
};

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <bnvform
            v-if       = "started === true"
            :name      = "schema_name"
            :schema    = "schema"
            :options   = "options"
            :record    = "record"
            :inline    = "inline"

            :key       = "form_reload"
            @reset     = "reset_form"
            @submitted = "operation_success"
            @saved     = "operation_success"
        ></bnvform>
    </div>

</template>


