//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(64),
            allowNull: false,
            unique: true,
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}


