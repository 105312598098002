//////////////////////////////

module.exports = (type) => {
    var schema = {
        sender: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        text: {
            type: type.TEXT,
            allowNull: false,
            defaultValue: '',
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        chat_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'chat_shops',
                key         : 'id',
                constraints : true,
                store_name  : 'chat_shop',
            }
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}


