<script>

const nprogress = require('nprogress');

export default {
    components: {
    },
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    computed: {
        shops_active() {
            return this.$store.state.shop.shops_active;
        },
        shops_not_active() {
            return this.$store.state.shop.shops_not_active;
        },
        tot_users() {
            return this.$store.state.user.tot_users;
        },
        tot_workers() {
            return this.$store.state.user.tot_workers;
        },
        tot_admins() {
            return this.$store.state.user.tot_admins;
        },
        tot_apps() {
            return this.$store.state.user.tot_apps;
        },
    },
    created () {
        this.$store.dispatch( 'shop/get_count_active' );
        this.$store.dispatch( 'shop/get_count_not_active' );
        this.$store.dispatch( 'user/get_all_count' );
    },
    methods: {
    },
    data () {
        return {
        }
    },
};

</script>

<template>

    <div>
        <h2 class="text-center">Home Page</h2>
        <div style="margin-top: 50px;">
            <div class="row state-overview justify-content-md-center" style="text-center">
                <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-success" style="font-size: 40px; color: white">
                            <icon name="store-alt"/>
                        </div>
                        <div class="value" style="font-weight: 800;">
                            <h1 class="count" style="font-weight: 800;">
                                {{ shops_active }}
                            </h1>
                            <p>Negozi attivi</p>
                        </div>
                    </section>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-secondary" style="font-size: 40px; color: white">
                            <icon name="store-alt"/>
                        </div>
                        <div class="value" style="font-weight: 800;">
                            <h1 class="count" style="font-weight: 800;">
                                {{ shops_active + shops_not_active }}
                            </h1>
                            <p>Negozi totali</p>
                        </div>
                    </section>
                </div>
                <!-- <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-secondary" style="font-size: 40px; color: white">
                            <icon name="store-alt"/>
                        </div>
                        <div class="value" style="font-weight: 800;">
                            <h1 class="count" style="font-weight: 800;">
                                {{ shops_not_active }}
                            </h1>
                            <p>Negozi non attivi</p>
                        </div>
                    </section>
                </div> -->
            </div>
            <div class="row state-overview justify-content-md-center" style="text-center">
                <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-success" style="font-size: 40px; color: white">
                            <icon name="user-tie"/>
                        </div>
                        <div class="value" style="font-weight: 800;">
                            <h1 class=" count2" style="font-weight: 800;">
                                {{ shops_active }}
                            </h1>
                            <p>Esercenti</p>
                        </div>
                    </section>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-warning" style="font-size: 40px; color: white">
                            <icon name="user"/>
                        </div>
                        <div class="value">
                            <h1 class=" count2" style="font-weight: 800;">
                                {{ tot_users }}
                            </h1>
                            <p style="font-weight: 800;">Clienti</p>
                        </div>
                    </section>
                </div>
            </div>
            <div class="row state-overview justify-content-md-center" style="text-center">
                <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-secondary" style="font-size: 40px; color: white">
                            <icon name="user-cog"/>
                        </div>
                        <div class="value">
                            <h1 class="count" style="font-weight: 800;">
                                {{ tot_admins }}
                            </h1>
                            <p style="font-weight: 800;">Utenti con accesso a Panel</p>
                        </div>
                    </section>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <section class="card">
                        <div class="symbol bg-dark" style="font-size: 40px; color: white">
                            <icon name="mobile-alt"/>
                        </div>
                        <div class="value">
                            <h1 class="count" style="font-weight: 800;">
                                {{ tot_apps }}
                            </h1>
                            <p style="font-weight: 800;">Applicazioni</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>

</template>


<style>
    .fa-icon {
      width: auto;
      height: 1em; /* or any other relative font sizes */
    
      /* You would have to include the following two lines to make this work in Safari */
      max-width: 100%;
      max-height: 100%;
    }
</style>
