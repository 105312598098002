////////////////////

import { userService } from '@/services';
import { router }      from '@/helpers';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null  };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { username, password }) {
            commit('loginRequest', { username });

            userService.login(username, password)
                .then(
                    user => {
                        if ( user.panel === true ) {
                            commit('loginSuccess', user);
                            router.push('/');
                            dispatch('alert/clear', null, { root: true });
                        } else {
                            commit('loginFailure', 'utente non abilitato');
                            dispatch('alert/error', 'utente non abilitato', { root: true });
                        }
                    },
                    error => {
                        commit('loginFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        logout({ dispatch, commit }) {
            var modules = [
                'shop', 'user',
            ];
            for ( var i = 0; i < modules.length; i++ ) {
                dispatch(`${ modules[ i ] }/clear`, null, { root: true });
            }
            userService.logout();
            commit('logout');
        },
        reset_password( { dispatch }, { email } ) {
            userService.reset_password( email )
                .then( (res) => {
                    dispatch('alert/success', res.message, { root: true });
                })
                .catch(error => {
                    dispatch('alert/error', error, { root: true });
                });
        },
        save_password( { dispatch }, { jwt, password, confirm_password } ) {
            userService.save_password( jwt, password, confirm_password )
                .then( (res) => {
                    dispatch('alert/success', res.message, { root: true });
                })
                .catch(error => {
                    dispatch('alert/error', error, { root: true });
                });
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    }
}
