<script>

const { listpage } = require('@/vue-model-mirror');
const schemas      = require('@/schemaconfig');

export default {
    name: 'user-list',
    extends: listpage,
    watch: {
        erased(new_val, old_val){
            if ( new_val === true && old_val === false ) {
                this.$refs.listbnv.page = 1;
            }
        },
    },
    computed: {
        filters: {
            get : function() {
                return this.$store.state.user.filters;
            },
            set : function (filters) {
                this.$store.dispatch('user/set_filters', filters);
            }
        },
        erased() {
            return this.$store.state.user.erased;
        },
    },
    methods: {
        export_data() {
            this.$store.dispatch( 'user/export' );
        },
        clear_search_form( modal_event ) {
            if ( modal_event.trigger === 'clear' ) {
                this.reset_search_form();
                this.$refs.search_form.submit_form();
            }
        },
        toggle_modal(modal_id = 'modal-filter') {
           // console.log(`modal_id.constructor.name = ${modal_id.constructor.name}`); /* su Chrome è PointerEvent su Safari è MouseEvent */
            modal_id = modal_id.constructor.name === 'MouseEvent' || 'PointerEvent' ? 'modal-filter' : modal_id; 
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        submit_search_form() {
            this.$refs.search_form.submit_form();
        },
        operation_success: function(data) {
            Object.keys( data ).map( x => {
                if ( data[ x ] === null ) {
                    this.$store.dispatch('user/remove_filter', x);
                } else {
                    var filter = {};
                    filter[ x ] = typeof data[ x ] === 'object' ? data[ x ].search : data[ x ];
                    this.$store.dispatch('user/add_filter', filter);
                }
            }); 
            this.toggle_modal();
            this.$refs.listbnv.page = 1;
        },
    },
    created() {
        console.log('ciao da user');
    },
    data () {
        return {
            form_reload      : 0,
            inline           : false,
            modal_filter_key : 0,
        };
    },
};

</script>

<template>

    <div style="width: 100%; margin: auto">
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <b-row style="margin-bottom: 10px">
            <b-col class="text">
                <b-row>
                    <b-button v-b-tooltip.hover title="Filtra" @click="toggle_modal" size="sm" variant="outline-success btn-sm /*mr-auto*/" style="margin-left: 10px;"><icon name="filter"/></b-button>
                    <b-button v-b-tooltip.hover title="Nuovo" v-if="add" @click="new_element();" size="sm" variant="outline-info" style="margin-right: 10px;"></b-button>
                    <b-button v-b-tooltip.hover title="Esporta" @click="export_data" size="sm" variant="outline-secondary btn-sm mr-auto" style="margin-left: 10px;">
                        <icon name="download"/>
                    </b-button>
                </b-row>
            </b-col>
        </b-row>
        <bnvlist
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :size            = "size"
            :formpath        = "item_form"
            @row_selected    = "row_selected"
            @right-click     = "right_click"
        ></bnvlist>

        <!-- modal filtri -->
        <b-modal
            id="modal-filter"
            title="Filtri"
            @hide="clear_search_form"
            button-size="sm"
            >
            <bnvform
                v-if       = "search_schema"
                :key       = "modal_filter_key"
                :name      = "search_schema"
                :schema    = "schema"
                :options   = "form_config"
                :record    = "filters"
                :submit    = "false"
                :inline    = "inline"
                ref        = "search_form"
                @submitted = "operation_success"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_search_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>




