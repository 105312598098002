//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: { // insegna
            type: type.STRING(1024),
            allowNull: true,
        },
        'mytypes.id': {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'types',
                key         : 'id',
                constraints : true,
                store_name  : 'type',
            }
        },
        ragione_sociale: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        codice_ateco: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        active: {
            type: type.BOOLEAN,
            allowNull: true,
            defaultValue: null,
        },
    };

    return schema;
}

