<script>

export default {

    name: 'category-subcategories',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div class="text-left" style="">
        <h6 v-for="child in this.item.children" style="display: inline" :key="child.id"><b-badge variant="light" style="margin-right: 7px;" pill>{{ child.name }}</b-badge></h6>
    </div>

</template>


<style>
    .testina {
        font-size: 98%;
    }
</style>
