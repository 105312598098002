////////////////////////////////////////

const user           = require('@/schemas/user.schema.js');
const shop           = require('@/schemas/shop.schema.js');
const role           = require('@/schemas/role.schema.js');
const type           = require('@/schemas/type.schema.js');
const app            = require('@/schemas/app.schema.js');
const search_user    = require('@/schemas/search_user.schema.js');
const search_shop    = require('@/schemas/search_shop.schema.js');
const sector         = require('@/schemas/sector.schema.js');
const category       = require('@/schemas/category.schema.js');

module.exports = {
    user,
    shop,
    role,
    type,
    app,
    search_shop,
    search_user,
    sector,
    category,
};
