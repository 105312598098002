//////////////////////////////

module.exports = (type) => {
    var schema = {
        last_name: { // insegna
            type: type.STRING(1024),
            allowNull: true,
        },
        'role_id': {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'roles',
                key         : 'id',
                constraints : true,
                store_name  : 'role',
            }
        },
        enabled: {
            type: type.BOOLEAN,
            allowNull: true,
            defaultValue: null,
        },
        panel: {
            type: type.BOOLEAN,
            allowNull: true,
            defaultValue: null,
        },
    };

    return schema;
}


